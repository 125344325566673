<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $vuetify.lang.t('$vuetify.route.equipmentChange') }}
      </v-card-title>
      <v-card-text
        v-if="currentEquipment"
      >
        <v-row>
          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.name') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipment.deviceId') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.deviceId }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.no') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.no }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.company') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.company }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.companyAddr') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.companyAddr }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.companyTel') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.companyTel }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.serviceDate') }} ({{ $vuetify.lang.t('$vuetify.common.month') }})
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.serviceDate }}
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="3"
              >
                {{ $vuetify.lang.t('$vuetify.equipmentTemplate.regid') }}
              </v-col>
              <v-col
                cols="9"
                class="black--text"
              >
                {{ currentEquipment.template.regid }}
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info darken-1"
          @click="handleCancel"
        >
           {{$vuetify.lang.t('$vuetify.common.cancel')}}
        </v-btn>
        <v-btn
          color="primary darken-1"
          @click="handleChangeClick"
        >
           {{$vuetify.lang.t('$vuetify.equipment.changeEquipment')}}
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="changeDialog"
        persistent
        max-width="800"
      >
        <v-card>
          <v-card-title>
            选择设备
          </v-card-title>
          <equipment-list />
          <v-card-actions>
            <v-btn
              :disabled="equipmentSelected.length <= 0"
              class="white--text"
              color="error"
              depressed
            >
              {{ $vuetify.lang.t('$vuetify.equipment.scanCodeConfirm') }}
              <v-icon right>
                mdi-content-save
              </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="info darken-1"
              @click="handleChangeDialogCancel"
            >
               {{$vuetify.lang.t('$vuetify.common.cancel')}}
            </v-btn>

            <v-btn
              :disabled="equipmentSelected.length <= 0"
              color="primary darken-1"
              @click="handleChangeDialogSubmit"
            >
               {{$vuetify.lang.t('$vuetify.common.submit')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import bus from '@/services/bus-event';

  export default {
    components: {
      EquipmentList: () => import('./equ')
    },
    props: {
      id: {
        type: String,
        default: () => null
      },
      patientId: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        changeDialog: false,
        equipmentSelected: []
      }
    },

    computed: {
      ...mapGetters({
        currentEquipment: 'equipment/currentEquipment'
      })
    },

    created () {
      if (this.id) {
        this.equipmentShow( this.id ).then( () => {
          this.editItem = this.currentEquipment
        } )
      }
    },

    mounted () {
      bus.on('equipment-change', this.onEquipmentChange);
    },

    beforeDestroy () {
      bus.off('equipment-change', this.onEquipmentChange);
    },

    methods: {
      ...mapActions({
        equipmentShow: 'equipment/show',
        equipmentChange: 'equipment/change'
      }),

      onEquipmentChange (data) {
        this.equipmentSelected = data
      },

      handleChangeClick () {
        this.changeDialog = true
      },

      handleCancel() {
        this.$router.go(-1)
      },

      handleChangeDialogCancel () {
        this.changeDialog = false
      },

      handleChangeDialogSubmit () {
        this.equipmentChange({ id: this.equipmentSelected[0].id, params: { pt_id: this.patientId } }).then( () => {
          this.changeDialog = false
          this.$router.go(-1)
        })
      }
    }
  }
</script>
